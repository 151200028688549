@charset "utf-8";

@import url('https://fonts.googleapis.com/css?family=Rajdhani:400,700');
body, html {
  margin: 0;
  padding: 0;
  font-family: 'こぶりなゴシック W3 JIS2004', 'Koburina Gothic W3 JIS2004', sans-serif;
  font-size: 16px;
  color: #565656;
  overflow-x: hidden;
}

#hero {
  position: relative;
  color: #fff;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .copy {
    padding: 0 5%;
    position: relative;
    h1 {
      text-align: center;
      img {
        max-width: 100%;
      }
    }
  }
}

.content-section {
  margin-bottom: 100px;
  .headline {
    color: rgb(240, 130, 0);
    font-size: 97px;
    @media screen and (max-width: 1000px) {
      font-size: 43.5px;
    }
    font-family: Rajdhani, sans-serif;
    text-transform: uppercase;
    font-weight: 400;
    padding-left: 5%;
  }
}

.anchor-block {
  position: relative;
  &__headline {
    width: 1000px;
    height: 500px;
    @media screen and (max-width: 1000px) {
      width: 100%;
      height: 250px;
    }
    margin-left: auto;
    overflow: hidden;
    position: relative;
    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 100px;
      height: 2px;
      background: #cacaca;
      top: 249px;
      left: 0;
      @media screen and (max-width: 1000px) {
        width: 50px;
        height: 1px;
        top: 124.5px;
      }
      z-index: 3;
      transform-origin: 0;
      transition: transform 1s cubic-bezier(0.175, 0.885, 0.32, 1);
    }
    img {
      transition: transform 1s cubic-bezier(0.175, 0.885, 0.32, 1), opacity 1s cubic-bezier(0.175, 0.885, 0.32, 1);
      @media screen and (max-width: 1000px) {
        transform: translate(-25%,-25%);
      }
      z-index: 1;
    }
    &::after {
      content: '';
      position: absolute;
      background: #f08200;
      mix-blend-mode: soft-light;
      opacity: 0;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }
  }
}
.anchor-screen {
  display: block;
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  margin-left: auto;
  width: 1000px;
  height: 500px;
  @media screen and (max-width: 1000px) {
    width: 100%;
    height: 250px;
    .mishina_banner_bg {
      width: 130%;
      max-width: 130%;
      transform: translateX(-20%);
    }
  }
  @media screen and (min-width: 1000px) {
    &:hover {
      .anchor-block__headline img {
        transform: scale(1.15);
      }
      .anchor-block__headline::before {
        transform: scaleX(0.5);
      }
      .anchor-block__headline::after {
        opacity: 1;
        -ms-filter: opacity(25%); /* because IE/Edge is not supported mix-blend-mode */
      }
      .anchor-logo {
        transform: translateX(70px);
      }
    }
  }
  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 100px;
    height: 2px;
    background: #777777;
    top: 249px;
    left: -100px;
    @media screen and (max-width: 1000px) {
      width: 50px;
      height: 1px;
      top: 124.5px;
      left: -50px;
    }
  }
}

.anchor-logo {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  transform: translateX(120px);
  transition: transform 1s cubic-bezier(0.175, 0.885, 0.32, 1);
  .mishina_banner {
    height: 120px;
  }
  @media screen and (max-width: 1000px) {
    transform: translateX(60px);
    img {
      max-width: calc(100% - 80px);
    }
    .mishina_banner {
      height: auto;
    }
  }
}

canvas {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0.2;
  z-index: -1;
}